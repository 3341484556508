import React from "react"
import Layout from "../components/layout"
import ComponentTextblock1 from "../components/component-textblock-1"
import ComponentTextblock2 from "../components/component-textblock-2"
import SectionP60 from "../components/section/section-p60"
import Seo from "../components/seo"
//import { graphql } from "gatsby";
//import { StaticImage } from "gatsby-plugin-image"

const PageAbout = ({ data, location }) => (
  <Layout location={location}>
    <Seo
      title="About us"
      description=""
    />
    <section className="sect sect_PageMission_catch sect-bg-bls">
      <div className="container">
        <ComponentTextblock1 
          title="Cyberdyne Care Robotics GmbH based in Bochum, North Rhine-Westphalia, was founded in August 2013."
          subtitle="The principal shareholder is Cyberdyne Inc. Japan."
          content="The company has primarily 3 business domains:"
        />
        <SectionP60>
          <ComponentTextblock2>(1) The company provides HAL treatment for individuals with brain-neuromuscular diseases, utilising various types of HAL systems to improve their ability to move on their own. The company also offers NeuroHALFIT, a neurological fitness program utilising the HAL Lumbar type</ComponentTextblock2>
          <ComponentTextblock2>(2) The company rents and sells products of Cyberdyne Inc., including the HAL system in the European region. As the European headquarters, the company runs the network of distributors for Cyberdne Inc. The company also serves as a showroom, welcoming visitors ranging from patients and their family members to medical professionals, researchers, and medical distributors. </ComponentTextblock2>
          <ComponentTextblock2>(3) The company serves as a point of contact for University hospitals and other research institutions for potential research collaborations in order to further develop other medical applications of the HAL system, including expanding its target patient population.</ComponentTextblock2>
        </SectionP60>
      </div>
    </section>
  </Layout>
)

export default PageAbout

